// @ts-ignore
import Loading from "react-fullscreen-loading";
// @ts-ignore
import reactManifest from "react-manifest";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

const Layout = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const path = useLocation().pathname;

  useEffect(() => {
    const manifestDetails = {
      short_name: "Ankiety - kaes.",
      name: "Ankiety kaes.",
      icons: [
        {
          src: "favicon.ico",
          sizes: "64x64 32x32 24x24 16x16",
          type: "image/x-icon",
        },
      ],
      start_url: "." + path,
      display: "standalone",
      theme_color: "#000000",
      background_color: "#ffffff",
    };

    reactManifest.update(manifestDetails, "#manifest-placeholder");
  }, []);

  return (
    <div className="main w-full mx-auto lg:w-11/12 lg:my-10">
      {/* NOTE => Loading */}
      <Loading loading={loading} background="#FFFFFF" loaderColor="#333333" />

      {/* NOTE => Content component */}
      <Routes>
        <Route path="/" element={<Content setLoading={setLoading} />} />
        <Route path="/:id" element={<Content setLoading={setLoading} />} />
      </Routes>

      {/* NOTE => Footer component */}
      <Footer />
    </div>
  );
};

export default Layout;
