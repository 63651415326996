import {
  Typography,
  Radio,
  Popover,
  PopoverHandler,
  PopoverContent,
  Chip,
} from "@material-tailwind/react";
import { QuestionProps, QuestionInputValues } from "../../types/survey";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useFormContext } from "react-hook-form";
import { findInputError, isInvalid } from "../../utils/formValidation";

const RadioInput: React.FC<QuestionProps> = ({
  id,
  order,
  popover,
  question,
  inputs,
  vertical,
  required,
}: QuestionProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const inputName = "question_" + id;
  const inputError = findInputError(errors, inputName);
  const invalid: boolean = isInvalid(inputError);

  return (
    <div className="survey-question">
      <div className="flex justify-between lg:flex-row md:flex-row flex-col-reverse lg:gap-10 gap-2">
        {/* NOTE Question */}
        <Typography className="w-3/4 text-on-background" variant="h6">
          {order}. {question}
        </Typography>

        {/* NOTE Show popover on the right side. */}
        {popover && (
          <Popover>
            <PopoverHandler>
              <Chip
                className="w-max h-max"
                color="pink"
                value="Info"
                icon={<QuestionMarkCircleIcon />}
              />
            </PopoverHandler>
            <PopoverContent className="z-10">
              <Chip
                color="pink"
                value="Informacja"
                className="w-max mb-2"
                icon={<QuestionMarkCircleIcon />}
              />
              {popover}
            </PopoverContent>
          </Popover>
        )}
      </div>

      {/* NOTE - Radio inputs. */}
      <div className={`ml-2 flex ${vertical ? "flex-col" : "gap-5"} mt-4`}>
        {inputs.map((e: QuestionInputValues, i: number) => {
          return (
            <Radio
              key={i}
              id={`question_${i + 1}_${id}`}
              color="brown"
              className={invalid ? "border border-red-500" : ""}
              label={e.label}
              value={e.value}
              {...register(inputName, {
                required: {
                  value: required,
                  message: "required",
                },
              })}
            />
          );
        })}
      </div>

      {/* NOTE Required warning value. */}
      {invalid && (
        <Chip
          value="To pole wyboru jest wymagane"
          className="w-max mt-5 lg:mx-0 md:mx-0 mx-auto"
          color="red"
        />
      )}
    </div>
  );
};

export default RadioInput;
