import {
  Card,
  Typography,
  Button,
  Stepper,
  Step,
} from "@material-tailwind/react";
import Logo from "../assets/images/logo.svg";
import RadioInput from "./Survey/RadioInput";
import TextAreaInput from "./Survey/TextAreaInput";
import TextInput from "./Survey/TextInput";
import { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { LoadingProps } from "../types/survey";
import SelectInput from "./Survey/SelectInput";
import DatepickerInput from "./Survey/DatepickerInput";
import { fetchSurvey, insertAnswer } from "../api/survey";
import { useParams } from "react-router-dom";

const Content: React.FC<LoadingProps> = ({ setLoading }: LoadingProps) => {
  const { id } = useParams();

  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);

  const [survey, setSurvey] = useState<any>(null);
  const [isError, setError] = useState(false);
  const [successInsert, setSuccessInsert] = useState<boolean | null>(null);
  const [saveLoading, setSaveLoading] = useState(false);

  const [counter, setCounter] = useState(30)
  const [startCounting, setStartCounting] = useState(false);

  // NOTE - Form instance.
  const methods = useForm({ mode: "onBlur" });
  const { handleSubmit } = methods;

  // NOTE - Handle next step.
  const handleNext = () => {
    setSaveLoading(true);

    // Handle submit and check validation errors.
    handleSubmit(async (data) => {
      if (!isLastStep) setActiveStep((cur) => cur + 1);
      else {
        const response = await insertAnswer(id!, data);

        if (response && response.status === "success") {
          setSuccessInsert(true);

          // Redirect after x secs.
          setStartCounting(true);
        } else {
          setSuccessInsert(false);
        }

        setSaveLoading(false);
      }
    })();
  };

  // NOTE - Handle previous step.
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  // NOTE - Load survey data by link name.
  const loadSurvey = async () => {
    setLoading(true); // Start loading.

    // Fetch survey.
    const response = await fetchSurvey(id!);

    if (response && response.status === "success") {
      const result = response.result;

      const groupBy = result.questions.reduce((group: any, item: any) => {
        const { step } = item;

        group[step] = group[step] ?? [];
        group[step].push(item);

        return group;
      }, {});

      result.questions = groupBy;
      setError(false);
      setSurvey(result);

      // Is only one step, set last step.
      Object.keys(result.questions).length === 1 && setIsLastStep(true);
    } else {
      setError(true);
    }

    setLoading(false); // Stop loading.
  };

  // NOTE - Load all required data here.
  useEffect(() => {
    loadSurvey();
  }, []);

  // NOTE - Counter to reload page
  useEffect(() => {
    let interval: any = null
    if (startCounting) {
      if (counter > 0) {
        interval = setInterval(() => setCounter(counter - 1), 1000)
      } else {
        // Reload page
        window.location.reload();
      }
    }
    return () => {
      interval && clearInterval(interval)
    }
  }, [startCounting, counter]);

  return (
    <Card className="w-full mb-5 py-10 pt-5 pb-20">
      <div className="header-logo">
        <img src={Logo} alt="logo-ct" className="lg:w-60 w-40 lg:my-10 my-5" />
      </div>
      <hr className="my-4 border-none" />
      <div className="lg:w-3/4 w-11/12 mx-auto border-solid border rounded-xl border-blue-gray-50 lg:p-20 px-7 py-10">
        {survey && !isError && successInsert == null && (
          <>
            <div className="survey-title">
              <Typography
                className="text-on-background text-center mb-5 lg:text-3xl text-xl"
                variant="h2"
              >
                {survey.name}
              </Typography>
              <Typography className="text-center lg:text-base text-sm">
                {survey.description}
              </Typography>
            </div>
            <div className="survey-content">
              <FormProvider {...methods}>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="lg:mt-20 mt-10 mb-2"
                  noValidate
                  autoComplete="off"
                >
                  {Object.keys(survey.questions).length > 1 && (
                    <Stepper
                      activeStep={activeStep}
                      isLastStep={(value) => setIsLastStep(value)}
                      isFirstStep={(value) => setIsFirstStep(value)}
                    >
                      {Object.keys(survey.questions).map(
                        (m: any, index: number) => {
                          return <Step key={index} className="h-4 w-4" />;
                        }
                      )}
                    </Stepper>
                  )}

                  {Object.keys(survey.questions).map(
                    (m: any, index: number) => {
                      return (
                        <div key={index}>
                          {activeStep === m - 1 && (
                            <div className={`step-${m} mt-20 space-y-10`}>
                              <>
                                {survey.questions[m].map(
                                  (q: any, i: number) => {
                                    if (q.type === "radio")
                                      return <RadioInput key={i} {...q} />;
                                    if (q.type === "textarea")
                                      return <TextAreaInput key={i} {...q} />;
                                    if (q.type === "text")
                                      return <TextInput key={i} {...q} />;
                                    if (
                                      q.type === "select" ||
                                      q.type === "stores"
                                    )
                                      return <SelectInput key={i} {...q} />;
                                    if (q.type === "datepicker")
                                      return <DatepickerInput key={i} {...q} />;
                                    return <div key={i}></div>;
                                  }
                                )}
                              </>
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                  <div className="survey-buttons mt-20 flex justify-between">
                    {Object.keys(survey.questions).length > 1 &&
                      activeStep !== 0 ? (
                      <Button
                        color="brown"
                        onClick={handlePrev}
                        disabled={isFirstStep}
                      >
                        Wróć
                      </Button>
                    ) : (
                      <div></div>
                    )}
                    <Button color="brown" onClick={handleNext}>
                      {isLastStep ? "Wyślij ankietę" : "Przejdź dalej"}
                    </Button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </>
        )}

        {successInsert === true && !isError && (
          <div>
            <div className="survey-title py-20">
              <Typography
                className="text-on-background text-center mb-5 lg:text-3xl text-xl"
                variant="h2"
              >
                Dziękujemy za przesłanie opinii
              </Typography>
              <Typography className="text-center lg:text-base text-sm">
                Jest ona dla nas szczególnie ważna, bo dzięki niej
                <br />
                możemy monitorować jakość naszej pracy i<br />
                zapewnić najwyższą jakość obsługi klienta.
              </Typography>
            </div>
            <div className="mt-15 text-center text-sm">
              {counter > 0 ? (
                <div className="d-block">
                  <span className="mb-5 block antialiased font-sans text-base font-light leading-relaxed text-inherit">Zostaniesz przekierowany na stronę główną za
                    <div className="ms-2 relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                      <span className="font-bold text-gray-600 dark:text-gray-300">{counter}</span>
                    </div>
                  </span>
                  <Button color="brown" onClick={() => window.location.reload()}>Powrót do strony głównej</Button>
                </div>
              ) : (
                <Typography className="mb-5">Przekierowywanie...</Typography>
              )}
            </div>
          </div>
        )}

        {successInsert === false && !isError && (
          <div className="survey-title py-20">
            <Typography
              className="text-on-background text-center mb-5 lg:text-3xl text-xl"
              variant="h2"
            >
              Wystąpił nieoczekiwany błąd podczas wysyłania opinii
            </Typography>
            <Typography className="text-center lg:text-base text-sm">
              Odśwież stronę i spróbuj ponownie
            </Typography>
          </div>
        )}

        {isError && (
          <div className="survey-title py-28">
            <Typography
              className="text-on-background text-center mb-5 lg:text-3xl text-xl"
              variant="h2"
            >
              Nie znaleziono ankiety o podanym adresie
            </Typography>
            <Typography className="text-center lg:text-base text-sm">
              Sprawdź czy wprowadzony przez Ciebie adres jest poprawny
            </Typography>
          </div>
        )}
      </div>
    </Card>
  );
};

export default Content;
