import {
  Typography,
  Popover,
  PopoverHandler,
  PopoverContent,
  Chip,
  Input,
} from "@material-tailwind/react";
import { QuestionProps, QuestionInputValues } from "../../types/survey";
import {
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { useFormContext, Controller } from "react-hook-form";
import { findInputError, isInvalid } from "../../utils/formValidation";
// @ts-ignore
import Datepicker from "tailwind-datepicker-react";
import { useState } from "react";
import moment from "moment";
import "moment/locale/pl";

moment.locale("pl");

const DatepickerInput: React.FC<QuestionProps> = ({
  id,
  order,
  popover,
  question,
  inputs,
  vertical,
  required,
}: QuestionProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  const {
    control,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext();

  const options = {
    autoHide: true,
    todayBtn: true,
    clearBtn: false,
    todayBtnText: "Dzisiaj",
    maxDate: new Date(),
    minDate: new Date("2008-01-01"),
    theme: {
      background: "bg-white",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "text-gray-300",
      input: "",
      inputIcon: "",
      selected: "",
    },
    icons: {
      // () => ReactElement | JSX.Element
      prev: () => <ChevronLeftIcon />,
      next: () => <ChevronRightIcon />,
    },
    weekDays: ["Pn", "Wt", "Śr", "Czw", "Pt", "Sb", "Nd"],
    datepickerClassNames: "top-auto datepicker-custom",
    defaultDate: new Date(),
    language: "pl",
  };

  return (
    <div className="survey-question">
      <div className="flex justify-between lg:flex-row md:flex-row flex-col-reverse lg:gap-10 gap-2">
        {/* NOTE Question */}
        <Typography className="w-3/4 text-on-background" variant="h6">
          {order}. {question}
        </Typography>

        {/* NOTE Show popover on the right side. */}
        {popover && (
          <Popover>
            <PopoverHandler>
              <Chip
                className="w-max h-max"
                color="pink"
                value="Info"
                icon={<QuestionMarkCircleIcon />}
              />
            </PopoverHandler>
            <PopoverContent className="z-10">
              <Chip
                color="pink"
                value="Informacja"
                className="w-max mb-2"
                icon={<QuestionMarkCircleIcon />}
              />
              {popover}
            </PopoverContent>
          </Popover>
        )}
      </div>

      {/* NOTE Select inputs. */}
      <div
        className={`ml-2 flex mt-4 ${vertical ? "lg:flex-col" : "lg:flex-row lg:space-x-5"
          } flex-col lg:space-y-0 space-y-5`}
      >
        {inputs.map((e: QuestionInputValues, i: number) => {
          const inputName = e.name + "_" + id;
          const inputError = findInputError(errors, inputName!);
          const invalid: boolean = isInvalid(inputError);

          return (
            <div key={i} className="flex lg:w-1/2 w-full flex-col">
              <Controller
                name={inputName}
                control={control}
                rules={{
                  required: {
                    value: required,
                    message: "required",
                  },
                }}
                render={() => (
                  <Datepicker
                    options={options}
                    onChange={(event: any) => {
                      setValue(inputName!, event);
                      setSelectedDate(moment(event).format("D MMMM YYYY"));

                      // Re-validate.
                      trigger();
                    }}
                    show={show}
                    setShow={(s: boolean) => setShow(s)}
                  >
                    <Input
                      onFocus={() => setShow(true)}
                      color="brown"
                      label={e.label}
                      value={selectedDate ?? ""}
                      error={invalid}
                      readOnly
                    />
                  </Datepicker>
                )}
              />

              {/* NOTE Required warning value. */}
              {invalid && (
                <Chip
                  value="To pole wyboru jest wymagane"
                  className="w-max mt-5 lg:mx-0 md:mx-0 mx-auto"
                  color="red"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DatepickerInput;
