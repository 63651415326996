import axios from "./axios";
import { AxiosResponse, AxiosError } from "axios";

export const fetchSurvey = async (slug: string) =>
  await axios
    .get(`/api/survey/${slug}`)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((e: AxiosError) => {
      return e.response?.data;
    });

export const insertAnswer = async (slug: string, answers: any) =>
  await axios
    .put(`/api/surveys/answer`, { slug, answers })
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((e: AxiosError) => {
      return e.response?.data;
    });
