import {
  Typography,
  Popover,
  PopoverHandler,
  PopoverContent,
  Chip,
  Input,
} from "@material-tailwind/react";
import { QuestionProps, QuestionInputValues } from "../../types/survey";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useFormContext } from "react-hook-form";
import { findInputError, isInvalid } from "../../utils/formValidation";

const TextInput: React.FC<QuestionProps> = ({
  id,
  order,
  popover,
  question,
  inputs,
  vertical,
  required,
}: QuestionProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="survey-question">
      <div className="flex justify-between lg:flex-row md:flex-row flex-col-reverse lg:gap-10 gap-2">
        {/* NOTE Question */}
        <Typography className="w-3/4 text-on-background" variant="h6">
          {order}. {question}
        </Typography>

        {/* NOTE Show popover on the right side. */}
        {popover && (
          <Popover>
            <PopoverHandler>
              <Chip
                className="w-max h-max"
                color="pink"
                value="Info"
                icon={<QuestionMarkCircleIcon />}
              />
            </PopoverHandler>
            <PopoverContent className="z-10">
              <Chip
                color="pink"
                value="Informacja"
                className="w-max mb-2"
                icon={<QuestionMarkCircleIcon />}
              />
              {popover}
            </PopoverContent>
          </Popover>
        )}
      </div>

      {/* NOTE Textarea inputs. */}
      <div
        className={`ml-2 flex mt-4 ${
          vertical ? "lg:flex-col" : "lg:flex-row lg:space-x-5"
        } flex-col lg:space-y-0 space-y-5`}
      >
        {inputs.map((e: QuestionInputValues, i: number) => {
          const inputName = e.name + "_" + id;
          const inputError = findInputError(errors, inputName!);
          const invalid: boolean = isInvalid(inputError);

          return (
            <div key={i} className="flex lg:w-1/2 w-full flex-col">
              <Input
                color="brown"
                error={invalid}
                label={e.label}
                {...register(inputName, {
                  required: {
                    value: required,
                    message: "required",
                  },
                })}
              />

              {/* NOTE Required warning value. */}
              {invalid && (
                <Chip
                  value="To pole tekstowe jest wymagane"
                  className="w-max my-5 lg:mx-0 md:mx-0 mx-auto"
                  color="red"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TextInput;
