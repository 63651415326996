import { ThemeProvider } from "@material-tailwind/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./routes/Layout";
import ErrorPage from "./routes/ErrorPage";

const App = () => {
  // NOTE => Custom theme settings.
  const customTheme = {};

  // NOTE => Application routes.
  const router = createBrowserRouter([
    {
      path: "*",
      element: <Layout />,
    },
  ]);

  return (
    <ThemeProvider value={customTheme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
