import { Typography, Card } from "@material-tailwind/react";
import Logo from "../assets/images/logo.svg";

const Footer = () => {
  return (
    <Card className="w-full bg-white p-8">
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 bg-white text-center md:justify-between">
        <img src={Logo} alt="logo-ct" className="w-20" />
        <Typography color="blue-gray" className="text-center font-normal">
          Wszelkie prawa zastrzeżone &copy; <b>2023 kaes.</b>
        </Typography>
      </div>
    </Card>
  );
};

export default Footer;
